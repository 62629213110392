module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Broadsign","short_name":"Broadsign","start_url":"/","background_color":"#FFFFFF","theme_color":"#001464","display":"minimal-ui","icon":"static/icons/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5973cf52c7e7cd7dbde7c45c8c9d0f1"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://broadsignstage.wpengine.com/graphql","verbose":true,"html":{"imageMaxWidth":1088,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"blurred","useGatsbyImage":true,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"develop":{"hardCacheData":true,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"schema":{"timeout":100000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"type":{"Post":{"limit":10000},"MediaItem":{"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Comment":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MRM8KCP","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmPreview":"GTM Preview","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["*.html"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
